<template>
  <CRow>
    <CCol sm="12" md="6" class="m-auto">
      <div class="container text-dark pt-5 p-3">
        <section v-for="field in fields" :key="field.key">
          <InputField :field="field" disabled :value="entityDetails[field.dataTableID]" />
        </section>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields () {
      let fields  = this.currentEntity.keys.reduce((acc, obj) => {
        if (obj.table?.displayOrder > 0) {
          acc.push(obj)
        }
        return acc 
      }, [])

      if (fields) {
        fields = fields.sort((field1, field2) => {
          return field1.table?.displayOrder - field2.table?.displayOrder
        })
      }

      return fields
    },
    ...mapState({
      is_loading: state => state.is_loading,
      currentEntity: state => state.currentEntity,
      entityDetails: state => state.entityDetails
    })
  },
  components: {
    InputField: () => import('@/core/components/base/InputField')
  },
  methods: {
    loadCurrentEntityTabs (params) {
      const queryParam = Object.keys(params).reduce((final ,param) => {
        if (param === 'entity') {
          return { ...final, entity: params[param] }
        } else {
          return {
            ...final,
            keys: this.tab.queryFilter,
            value: params[param]
          }
        }
      }, {})

      this.loadEntityTabs({ entity: queryParam.entity, tab: true, keys: queryParam.keys, value: queryParam.value })
    },
    ...mapActions([
      'loadEntityTabs'
    ])
  },
  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler: 'loadCurrentEntityTabs'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
